import React, { useState } from "react";
import { Divider, Drawer, DrawerContent, Tooltip } from "@cpchem/covalence-ui";
import { Button, Card, Badge } from "../../components";
import "./styles.css";
import {
    Restricted,
    Authorized,
    Unauthorized,
    useAuth
} from "@cpchem/azure-auth-react";

import AuthorizedSample from "../../components/authorized-card";
import { TestIds } from "./test-ids";

export function CovalenceSample(): JSX.Element {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { currentAccount } = useAuth({});
    return (
        <>
            <Card gap testId="covalence-card">
                <Tooltip
                    content="Sample Tooltip Content"
                    side="right"
                    testId={TestIds.Tooltip}
                >
                    <h1>Covalence Sample Page</h1>
                </Tooltip>
                <Divider />
                <Button
                    className="drawerButton"
                    color="primary"
                    onClick={() => setDrawerOpen(true)}
                    text="Open Drawer"
                    testId={TestIds.DrawerLaunchButton}
                />
                <Drawer
                    isOpen={drawerOpen}
                    onRequestClose={() => setDrawerOpen(false)}
                    title="Sample Drawer Header"
                    testId={TestIds.Drawer}
                >
                    <DrawerContent>
                        <p>Sample Drawer Content</p>
                    </DrawerContent>
                </Drawer>
            </Card>
            <Card gap className="restricted-wrapper">
                <Restricted toRoles={["SPA.Developer"]}>
                    <>
                        <Authorized>
                            <div>
                                <Badge
                                    text={"Authorized"}
                                    variant="solid"
                                    color="green"
                                    testId={TestIds.AuthorizedBadge}
                                />
                                <p>
                                    This is using the Restricted component
                                    wrapper.
                                </p>
                            </div>
                        </Authorized>
                        <Unauthorized>
                            <div>
                                <Badge
                                    text={"UnAuthorized"}
                                    variant="solid"
                                    color="red"
                                    testId={TestIds.UnauthorizedBadge}
                                />
                                <p>
                                    This is using the Restricted component
                                    wrapper.
                                </p>
                            </div>
                        </Unauthorized>
                    </>
                </Restricted>
            </Card>
            <AuthorizedSample />
            <Card>
                {currentAccount?.isGuest ? (
                    <Badge text="Welcome Guest!" testId={TestIds.GuestBadge} />
                ) : (
                    <Badge
                        text={`Welcome Back, ${currentAccount?.username}!`}
                        testId={TestIds.KnownUserBadge}
                    />
                )}
            </Card>
        </>
    );
}
