import React from "react";
import { Badge, Card } from "@cpchem/covalence-ui";

export function UnAuthorizedSample(): JSX.Element {
    return (
        <Card gap>
            <Badge text={"UnAuthorized"} variant="solid" color="red" />
            <div>
                <p>This is using the RestrictedBy HOC </p>
            </div>
        </Card>
    );
}
