import React from "react";
import { Badge, Card } from "@cpchem/covalence-ui";

import { RestrictedBy } from "@cpchem/azure-auth-react";
import { UnAuthorizedSample } from "../unauthorized-card";

function AuthorizedSample(): JSX.Element {
    return (
        <Card gap>
            <Badge text={"Authorized"} variant="solid" color="green" />
        </Card>
    );
}
export default RestrictedBy({
    approvedRoles: ["Developer"],
    unauthorizedView: UnAuthorizedSample()
})(AuthorizedSample);
