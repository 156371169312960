import React from "react";
import { Routes, Route } from "react-router-dom";
import { AlertBanner, Grid } from "@cpchem/covalence-ui";
import { CovalenceSample, LandingPage, PersonalizationExample } from "../pages";
import { AppBar } from "./components/template-app-bar";
import { routePath } from "./route-paths";

export function App(): JSX.Element {
    return (
        <Grid className="app">
            <AppBar />
            <AlertBanner />
            <div className="main">
                <Routes>
                    <Route path={routePath.LANDING} element={<LandingPage />} />
                    <Route
                        path={routePath.PERSONALIZATION}
                        element={<PersonalizationExample />}
                    />
                    <Route
                        path={routePath.SAMPLE}
                        element={<CovalenceSample />}
                    />
                </Routes>
            </div>
        </Grid>
    );
}

export default App;
