import * as msal from "@azure/msal-browser";
import { LogLevel } from "@cpchem/logging";

export interface ProvidedConfig {
    api: {
        personalization: {
            url: string;
            scopes: string[];
        };
    };
    msal: msal.Configuration;
    logging: {
        appInsights: {
            connectionString: string;
            sinkPageViews?: boolean;
            sinkLogsAtOrAboveLevel?: LogLevel;
        };
        logRocketAppId: string;
    };
    mocks?: {
        mockUserEndpoint: string;
    };
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}

export function getProvidedConfig(env?: string): ProvidedConfig {
    return env === "development"
        ? {
              // THESE ARE DEVELOPMENT VALUES AND WILL NEED TO BE CHANGED IN YOUR IMPLEMENTATION
              api: {
                  personalization: {
                      url: "https://api-dev.cpchem.com/personalization/v1/personalization",
                      scopes: [
                          "https://api-dev.cpchem.com/Personalization.FullAccess"
                      ]
                  }
              },
              // THESE ARE DEVELOPMENT VALUES AND WILL NEED TO BE CHANGED IN YOUR IMPLEMENTATION
              msal: {
                  auth: {
                      authority:
                          "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a",
                      clientId: "317cf496-ea86-41e0-a04a-c84ecda64cc9",
                      redirectUri: "https://localhost:3000/auth_redirect"
                  }
              },
              // THESE ARE DEVELOPMENT VALUES AND WILL NEED TO BE CHANGED IN YOUR IMPLEMENTATION
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=787124fe-0e0e-4055-b994-d1064480127a;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/",
                      sinkPageViews: true,
                      sinkLogsAtOrAboveLevel: LogLevel.ERROR
                  },
                  logRocketAppId: "cpchem/test"
              },
              mocks: {
                  mockUserEndpoint: "http://localHost:59850/getMockUser"
              }
          }
        : getConfigFromIndex();
}
