import React, { useEffect, useState } from "react";
import { Button, TextField } from "../../components";
import {
    PersonalizationService,
    PersonalizationServiceKey,
    ServiceLocator
} from "@services";
import "./styles.scss";
import { TestIds } from "./test-ids";

export function PersonalizationExample(): JSX.Element {
    const personalizationService = ServiceLocator.get<PersonalizationService>(
        PersonalizationServiceKey
    );

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [response, setResponse] = useState("");

    async function getAllPersonalization() {
        const response = await personalizationService.getAllPersonalization();
        setResponse(JSON.stringify(response.data, null, 4));
    }

    useEffect(() => {
        getAllPersonalization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="main">
            <header className="main-header personalization-header">
                <div>
                    Single Page Application
                    <br />
                    ——Personalization——
                    <br />
                    <br />
                </div>
                <div className="personalization-buttons">
                    <Button
                        className="user-get-color"
                        color="purple"
                        onClick={async () => {
                            getAllPersonalization();
                        }}
                        size="medium"
                        text="Get All"
                        variant="solid"
                        testId={TestIds.GetAllButton}
                    />
                    &nbsp;
                    <Button
                        className="user-get-color"
                        color="green"
                        onClick={async () => {
                            if (key) {
                                const response =
                                    await personalizationService.getPersonalizationByKey(
                                        key
                                    );
                                setResponse(
                                    JSON.stringify(response.data, null, 4)
                                );
                            }
                        }}
                        size="medium"
                        text="Get By Key"
                        variant="solid"
                        testId={TestIds.GetByKeyButton}
                    />
                    &nbsp;
                    <Button
                        className="user-save-color"
                        color="primary"
                        onClick={() => {
                            if (key) {
                                personalizationService.savePersonalizationByKey(
                                    key,
                                    JSON.stringify(value)
                                );
                            }
                        }}
                        size="medium"
                        text="Save"
                        variant="solid"
                        testId={TestIds.SaveButton}
                    />
                    &nbsp;
                    <Button
                        className="user-delete-color"
                        color="red"
                        onClick={() => {
                            if (key) {
                                personalizationService.deletePersonalizationByKey(
                                    key
                                );
                            }
                        }}
                        size="medium"
                        text="Delete"
                        variant="solid"
                        testId={TestIds.DeleteButton}
                    />
                </div>
                <div className="personalization-field">
                    <TextField
                        description={`Key used in DB Primary Key, appended to api route ...v1/personalization/<key>`}
                        label="Key"
                        onChange={(value: string) => {
                            setKey(value);
                        }}
                        value={key}
                        testId={TestIds.KeyInput}
                    />
                </div>
                <div className="personalization-field">
                    <TextField
                        description="JSON object stored in database for the above key"
                        label="Value"
                        onChange={(value: string) => {
                            setValue(value);
                        }}
                        value={value}
                        testId={TestIds.ValueField}
                    />
                </div>
                <div className="personalization-field">
                    <p className="response-title">Response</p>
                    <div
                        className="response-output"
                        data-testId={TestIds.ApiResponse}
                    >
                        <pre>{response}</pre>
                    </div>
                </div>
            </header>
        </div>
    );
}
