import React, { useState } from "react";
import logo from "../../logo.svg";
import "./styles.css";
import { TestIds } from "./test-ids";

export function LandingPage(): JSX.Element {
    const [count, setCount] = useState(0);

    return (
        <div className="main">
            <header className="main-header">
                <img src={logo} className="main-logo" alt="logo" />
                <p>Hello from Vite + React {React.version}!!!!!</p>
                <p>
                    <button
                        type="button"
                        onClick={() => setCount((count) => count + 2)}
                        data-testid={TestIds.CountButton}
                    >
                        count is: {count}
                    </button>
                </p>
                <img
                    src="/cpchem.jpg"
                    alt="Chevron Phillips Chemical Company LLC"
                />
                <span>
                    Edit <code>App.tsx</code> and save to test HMR updates.
                </span>
                <p>
                    <a
                        className="App-link"
                        href="https://reactjs.org"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn React
                    </a>
                    {" | "}
                    <a
                        className="App-link"
                        href="https://vitejs.dev/guide/features.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Vite Docs
                    </a>
                    {" | "}
                    <a className="App-link" href="/sample">
                        Covalence Sample
                    </a>
                    {" | "}
                    <a className="App-link" href="/personalization">
                        Personalization Example
                    </a>
                </p>
            </header>
        </div>
    );
}
