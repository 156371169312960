import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar as CovalenceAppBar } from "@cpchem/covalence-ui";
import { LogoIcon } from "../../../components/icons";

export function AppBar(): JSX.Element {
    const navigate = useNavigate();

    function handleIconClick() {
        navigate("/");
    }

    return (
        <CovalenceAppBar
            className="header sticky-header"
            applicationName="Digital SPA Template w/ Covalence"
            icon={<LogoIcon />}
            onIconClick={handleIconClick}
        />
    );
}
